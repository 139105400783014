import en from '~/locales/en.json'
import hk from '~/locales/hk.json'

const messages = { en, hk }

export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'en' as keyof typeof messages,
    messages
}))
